/**
 * Decode mailto links
 * @returns {void}
 */
const handleMailtoDecode = () => {
  const anchorLinks = Array.from(document.querySelectorAll('[data-js-element="mailto__email-link"]'));

  anchorLinks.forEach((link) => {
    const encodedEmail = link.dataset.email;

    if (encodedEmail) {
      try {
        const decodedEmail = decodeURIComponent(encodedEmail);
        const mailtoLink = `mailto:${decodedEmail}`;
        // eslint-disable-next-line no-param-reassign
        link.href = mailtoLink;
      } catch (e) {
        console.error('Error decoding email:', e);
      }
    }
  });
};

/**
 * Custom anchor handling
 * - Checks for anchors in urls
 * - Adds custom scroll to anchor links
 * @returns {void}
 */
export default () => {
  handleMailtoDecode();
};
